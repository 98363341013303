.c-ant-table {
    .ant-table {
        .ant-table-title {
            background-color: #EC9E56;
            color: #000000;

            .left {
                text-align: left;
                font-weight: bold;
            }

            .right {
                text-align: right;
                font-size: 12px;
                font-style: italic;
            }
        }

        .ant-table-container {
            .ant-table-header {
                
            }
            .ant-table-content {
                .ant-table-thead {
                    .ant-table-cell {
                        background-color: #FFFFFF;
                        color: #000000;
                        border-right: 3px solid transparent !important;
                        border-bottom: 2px solid #EC9E56;
                        &:hover {
                            background-color: #FFFFFF;
                        }
                        .anticon {
                            svg {
                                fill: #000000;
                            }
                            &.active {
                                svg {
                                    fill: #EC9E56;
                                }
                            }
                        }
                        .ant-table-filter-trigger {
                            .anticon {
                                svg {
                                    fill: #000000;
                                }
                            }
                            &.active {
                                .anticon {
                                    svg {
                                        fill: #EC9E56;
                                    }
                                }
                            }
                        }
                    }
                }

                .ant-table-tbody {
                    .ant-table-row {
                        cursor: pointer;

                        .ant-table-cell {
                            border-right: 2px solid transparent !important;
                            border-bottom: 1px solid #EC9E56;
                        }
                    }
                }
            }
        }
    }
    .c-ant-table-summary {
        .ant-table-cell {
            background: #EC9E56;
            color: #000000;
        }
    }
}

.c-ant-table-expend {
    .ant-table {
        .ant-table-container {
            .ant-table-tbody {
                .ant-table-row {
                    cursor: pointer;

                    &.ant-table-row-level-0 {
                        .ant-table-cell {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.c-ant-picker {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: #FFFFFF !important;
    padding-left: 3px;

    &:hover {
        background: transparent;
        border: none;
        color: #FFFFFF;
    }
    .ant-picker-input {
        input {
            color: #FFFFFF !important;
            width: 80px;
        }
    }
}

// .c-ant-table-filter-dropdown {
//     .ant-btn-primary {
//         background: #EC9E56;
//         opacity: 0.8;
//         &:hover {
//             background: #EC9E56 !important;
//             opacity: 1;
//         }
//     }
//     .ant-btn-default {
//         &:hover {
//             border-color: #EC9E56 !important;
//             color: #EC9E56 !important;
//         }
//     }
//     .ant-btn-link {
//         color: #EC9E56 !important;
//         &:hover {
//             color: #EC9E56 !important;
//         }
//     }
// }

.ant-table {
    .ant-table-container {
        .ant-table-thead {
            .ant-table-cell {
                text-align: center !important;
                background-color: #1F4172;
                color: #FFFFFF;
                .anticon {
                    svg {
                        fill: #FFFFFF;
                    }
                    &.active {
                        svg {
                            fill: #1677ff;
                        }
                    }
                }
                .ant-table-filter-trigger {
                    .anticon {
                        svg {
                            fill: #FFFFFF;
                        }
                    }
                    &.active {
                        .anticon {
                            svg {
                                fill: #1677ff;
                            }
                        }
                    }
                }
                &.ant-table-column-has-sorters {
                    &:hover {
                        background-color: #1F4172;
                    }
                }
            }
        }
        .ant-table-tbody {
            .ant-table-row {
                cursor: pointer;
            }
        }

        .ant-table-summary {
            background-color: #1F4172;
            color: #FFFFFF;
            font-weight: bold;
        }
    }
}

div[data-highcharts-chart] {
    overflow: initial !important;
}

.highcharts-credits {
    display: none !important;
}

.highcharts-empty-series {
    stroke: transparent !important;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 320px) {
    .header {
        .right {
            .calendar {
                max-width: 26px !important;
            }
        }
    }
}

@media (min-width: 480px) {
    .header {
        .right {
            .calendar {
                max-width: 26px !important;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}