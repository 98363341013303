.pie-box {
    text-align: center;
}
.pie-label {
    font-size: 16px;
}
.pie-value {
    font-size: 12px;
    font-weight: 100;
}
.c-stock-alloc {
    background: rgba(217, 217, 217, 0.3);
    border: 0.5px solid #1F4172;
    border-radius: 10px;
}