.chakra-slider {
    .above-text {
        position: relative;
        height: 150px;
        width: 65px;
        display: block;
        text-align: center;
        color: #1AC917;

        > span {
            position: absolute;
            left: -10px;
        }

        &::after {
            border-right: 1px solid #1F4172;
            height: 5px;
            left: 5px;
            position: absolute;
            top: 20px;
            width: 5px;
            z-index: 0;
            content: "";
            color: #1F4172;
        }
    }

    .below-text {
        position: relative;
        height: 150px;
        width: 65px;
        left: 5px;
        display: block;
        text-align: center;
        padding-top: 65px;

        time {
            position: absolute;
            left: -10px;
        }

        &::before {
            border-right: 1px solid #1F4172;
            content: "";
            display: block;
            height: 30px;
            left: 5px;
            position: absolute;
            top: 40px;
            width: 5px;
            z-index: 0;
        }

        &::after {
            border-right: 1px solid #1F4172;
            height: 15px;
            left: 5px;
            position: absolute;
            top: 85px;
            width: 5px;
            z-index: 0;
            content: "\2022";
            color: #1F4172;
            font-size: 24px;
            line-height: normal;
        }
    }

    .chakra-slider__thumb {
        top: calc(-50% + 19px);
        background: transparent;
        box-shadow: none;
        border: 4px solid transparent;
        border-bottom: 6px solid #FF9900;
        border-top: 0;
        border-radius: 0;
        width: 8px;
        height: 8px;
        &.c-ant-slider-thumb-top {
            top: -6px;
            border-top: 6px solid #FF9900;
            border-bottom: 0;
        }
    }
}

.chakra-progress {
    svg {
        width: 100%;
        height: 100%;
    }
}

.chakra-progress-gray {
    .chakra-progress {
        .chakra-progress__track {
            fill: #D9D9D9;
        }
    }
}