.c-ant-stock-alloc {
    margin-left: 15px;
    margin-right: 15px;
    padding: 30px 0px 10px 0px;
    border-bottom: 1px solid black;
    &:last-child {
        border-bottom: none;
    }
    .c-ant-slider {
        &.chakra-slider {
            .chakra-slider__thumb {
                top: calc(-50% - -15px);
                background: transparent;
                box-shadow: none;
                border: 4px solid transparent;
                border-top: 6px solid #DA1818;
                border-bottom: 0;
                border-radius: 0;
                width: 8px;
                height: 8px;
            }
            .chakra-slider__track {
                background: linear-gradient(269.94deg, #0300A3 29.58%, rgba(167, 167, 174, 0.1) 99.95%);
                .chakra-slider__filled-track {
                    background: transparent;
                }
            }
        }
    }
}