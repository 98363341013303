.ant-table {
    .ant-table-content {
        .ant-table-thead {
            .ant-table-cell {
                background: #1F4172;
                color: #FFFFFF;
                text-align: center !important;
                &::before {
                    display: none;
                }
            }
        }
    }
}