button {
    &.import {
        border-radius: 50%;
        width: 62px;
        height: 62px;
        background: linear-gradient(319.84deg, #1F4172 20.05%, rgba(31, 65, 114, 0.31) 64.98%, rgba(217, 217, 217, 0) 91.94%);
        box-shadow: inset -10px -10px 30px #FFFFFF, inset 11px 10px 10px rgba(0, 0, 0, 0.2);
        filter: drop-shadow(10px 11px 30px rgba(0, 0, 0, 0.6));
        font-size: 12px;
        color: #1F4172;
    }
}

input::file-selector-button {
    padding: 0.5em 1em;
    border: none;
    background: rgba(217, 217, 217, 0.8);
    border-radius: 10px;
    cursor: pointer;

}