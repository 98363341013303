.highcharts-container {
    overflow: visible !important;
}
.MyChartTooltip {
    position: relative;
    z-index: 50;
    border: 0.5px dashed #1F4172;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 5px;
    width: 300px;
    .ant-col {
        font-size: 9pt;
    }
}